import React from 'react';
import gameService from '../../services/gameService';
import statisticsService from '../../services/statisticsService';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';

function Result() {
    const { gameTest } = useSelector((state: AppState) => state.general.integrationParams);

    const score = gameService.getScore();

    const resume = () => {
        window.top?.postMessage(
            JSON.stringify({
                finish: true,
                results: statisticsService.getStatistics(),
            }),
            '*'
        );
    };

    return (
        <div className="game">
            <div className="game-end__overlay">
                <div className="game-end">
                    <div className="game-end__inner">
                        <p className="game-end__title">Ваш результат</p>
                        <div className="game-score game-end__score">
                            <div className="game-score__icon" />
                            <p className="game-score__title">{score}</p>
                        </div>
                        {gameTest && (
                            <div
                                className="button button_red button_l game-start__button"
                                onClick={() => resume()}
                            >
                                Продолжить
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result;
