import ReactDOM from 'react-dom';
import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ConfigProvider } from 'antd';
import ru_Ru from 'antd/lib/locale-provider/ru_RU';

import reducers from './store/reducers';
import dispatherService from './services/dispatherService';
import storeService from './services/storeService';
import App from './App';

import pjson from '../package.json';
import './css/index.scss';
import actions from './store/actions/types';
import integrationService from './services/integrationService';

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot');

dispatherService.setParams(root);

(async () => {
    storeService.getStore().dispatch({
        type: actions.general.FETCH_CONFIG,
    });

    let loadConfigRequest = await integrationService.loadConfig();

    if (loadConfigRequest.status === 200) {
        const config = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_SUCCESS,
            payload: config,
        });
    } else {
        let body = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_ERROR,
            payload: body,
        });

        storeService.getStore().dispatch({
            type: actions.general.SET_MODE,
            payload: 'warning',
        });
    }
})();

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_Ru}>
            <App />
        </ConfigProvider>
    </Provider>,
    root
);

console.log(`%c Tap Tap version: ${pjson.version} `, 'background-color: #ffdd2d; color: #333;');
