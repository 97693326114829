const actions = {
    general: {
        SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
        SET_MODE: 'SET_MODE',
        SET_WARNING_TYPE: 'SET_WARNING_TYPE',
        FETCH_CONFIG: 'FETCH_CONFIG',
        FETCH_CONFIG_SUCCESS: 'FETCH_CONFIG_SUCCESS',
        FETCH_CONFIG_ERROR: 'FETCH_CONFIG_ERROR',
    },
};

export default actions;
