import storeService from './storeService';
import actions from '../store/actions/types';
import IntegrationParams from '../types/IntegrationParams';
import Mode from '../types/Mode';
import WarningType from '../types/WarningType';
import urlParams from './urlParams';

class DispatcherService {
    setParams = (element: HTMLElement | null): void => {
        const iframeParams = urlParams.getUrlParams(window.location.href);
        const token = iframeParams.token || element?.getAttribute('data-user-token');
        const unti_id = iframeParams.unti_id || element?.getAttribute('data-unti-id');
        const activity_uuid =
            iframeParams.activity_uuid || element?.getAttribute('data-activity-uuid');
        const darkMode = iframeParams.darkMode || element?.getAttribute('data-dark-mode');
        const gameTest = iframeParams.gameTest || element?.getAttribute('data-game-test');

        storeService.getStore().dispatch({
            type: actions.general.SET_INTEGRATION_PARAMS,
            payload: {
                token: token,
                unti_id: unti_id,
                activity_uuid: activity_uuid,
                darkMode: !!darkMode,
                gameTest: !!gameTest,
            } as IntegrationParams,
        });
    };

    setMode = (mode: Mode): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_MODE,
            payload: mode,
        });
    };

    setWarningType = (type: WarningType): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_WARNING_TYPE,
            payload: type,
        });
    };
}

const dispatcherService = new DispatcherService();
export default dispatcherService;
